export const Base_Url = process.env.REACT_APP_BASE_URL;
 
export const Base_Url1 = process.env.REACT_APP_BASE_URL;

export const Addwarehouse='createwarehouse'

export const Warehouselist='warehouseslist'

export const priceEstimator='priceEstimator'

export const createShippment='createShipment'

export const reverseShipment = 'reverseShipment'

export const forwardorderlist ='forwardorderlist'

export const shipmentlabel = 'shipmentlabel'

export const  pickupRequest = 'pickupRequest'

export const track = 'track'

export const csvuplode = 'csvuplode'

export const  registerapi = 'register'

export const loginapi = 'login'

export const dashboard = 'dashboard'

export const getpincode = 'getpincode'

export const ndrList = 'ndrList'

export const  ndr = 'ndr'

export  const deletewarehouse = 'deletewarehouse'

export const  Reattempt = "Reattempt"

export const  Return = "Return"

export const pendingApi = "Pending";

export  const uplodeDocument = "uplodeDocument"

export  const ndrStatusList = "ndrStatusList"