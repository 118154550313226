
import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash, faUser, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../component/spinner/spinner';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Base_Url, registerapi } from '../../config/config';

const schema = z.object({
  email: z.string().email('Invalid email address').nonempty('Email is required'),
  password: z.string().min(6, 'Password must be at least 6 characters long').nonempty('Password is required'),
  phone: z.string().min(10, 'Phone must be at least 10 characters long').nonempty('Phone is required'),
  name: z.string().min(1, 'Name is required').nonempty('Name is required'),
  company: z.string().optional(),
});

const SignUpPage = ({ setAuth }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isshowPassword, setIsshowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  // useEffect(()=>{
  //   const token=localStorage.getItem('authToken');
  //   if(token){
  //     navigate('/dashboard')
  //   }

  // },[])

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const name = data.name;
    if (isChecked == true) {
      axios.post(Base_Url + registerapi,{
      // axios.post ("http://192.168.1.36/backendci4/api/register",{
        name: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        address: "",
        company_name: data.company
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          setIsSubmitting(false);
          if (response.data.status === 'SUCCESS') {
            toast.success("Sign up successful!");
            localStorage.setItem('userDataSignup', JSON.stringify(response.data));
            navigate('/updateKYc');
          }
          else
          {
            toast.error(response.data.message.phone || response.data.message.email || "An error occurred.");
          }
        })
        .catch(function (error) {
          setIsSubmitting(false);
          toast.error("Sign up Failed!");
        });

    }
    else {
      toast.error('Please accept over terms and condition')
      setIsSubmitting(false)
    }
  };

  return (
    <div>
      <div style={{ backgroundImage: `url('/assets/images/texture.jpg')` }}
        className='-z-10 h-full w-full opacity-10 fixed top-0 left-0 pointer-events-none'>
      </div>
      <div className='relative z-10 grid grid-cols-1 md:grid-cols-3 gap-4  font-public-sans  h-full'>
        <div className='col-span-1 md:col-span-2 flex flex-col justify-between mt-4 md:mt-24 ml-8 '>
          <div className="m-2 md:m-0 md:p-2">
            <div className='flex flex-row gap-1 md:gap-2'>
              <h1 className='text-xl md:text-5xl font-bold font-open-sans text-gray-800'>
                SHIPPED MORE THAN
              </h1>
              <h1 className='text-xl md:text-5xl font-bold font-open-sans text-blueMain'>
                1 CRORE
              </h1>
            </div>
            <h1 className='text-xl md:text-5xl font-bold font-open-sans text-gray-800 mt-4'>
              PACKETS IN PREVIOUS QUARTER
            </h1>
            <p className='mt-4 text-base md:text-lg font-open-sans font-semibold'>
              We are the fastest-growing logistics company providing service in B2B & E-commerce segment.
              We have the best-in-class team, and our customer support team is working 24X7.
            </p>
            <div className='w-full md:w-96 h-auto md:h-80'>
              <img src='/assets/images/man.png' alt='Man' className='object-cover w-full h-full' />
            </div>
          </div>
        </div>
        <div className='col-span-1 bg-gray-200 p-4 flex items-center justify-center'>
          <section className="">
            <div className="text-center mb-2">
              <img className="mx-auto " src="/assets/images/logo1.png" width="170" height="150" alt="Logo" />
              <h5 className="text-xl font-sans" style={{ fontFamily: 'Open Sans, Public Sans, sans-serif' }}>Signup to PICKUPXPRESS</h5>
            </div>
            <div className="w-950 max-w-md bg-gray-100 p-6 rounded-xl mb-10 border-gray-200 border font-public-sans shadow-lg">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative mb-5 font-public-sans">
                  <span className="absolute inset-y-0 left-0 flex pl-3 items-center">
                    <FontAwesomeIcon icon={faUser} className="text-black-300" />
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-3 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
                    placeholder="Name"
                    {...register('name')}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs absolute top-full mt-0 left-0 w-full">
                      {errors.name.message}
                    </p>
                  )}
                </div>

                <div className="relative mb-5 font-public-sans">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FontAwesomeIcon icon={faHome} className="text-black-300" />
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-3 py-2 border ${errors.company ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
                    placeholder="Company name"
                    {...register('company')}
                  />
                </div>

                <div className="relative mb-5 font-public-sans">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FontAwesomeIcon icon={faEnvelope} className="text-black-300" />
                  </span>
                  <input
                    type="email"
                    className={`w-full pl-10 pr-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
                    placeholder="Email"
                    {...register('email')}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs absolute top-full mt-0 left-0 w-full">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className="relative mb-5 font-public-sans">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FontAwesomeIcon icon={faPhone} className="text-black-300" />
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-3 py-2 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
                    placeholder="Mobile no."
                    {...register('phone')}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-xs absolute top-full mt-0 left-0 w-full">
                      {errors.phone.message}
                    </p>
                  )}
                </div>

                <div className="relative mb-5 font-public-sans">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FontAwesomeIcon icon={faLock} className="text-black-300" />
                  </span>
                  <input
                    type={isshowPassword ? 'text' : 'password'}
                    className={`w-full pl-10 pr-3 py-2 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
                    placeholder="Password"
                    {...register('password')}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setIsshowPassword(!isshowPassword)}
                  >
                    <FontAwesomeIcon icon={isshowPassword ? faEye : faEyeSlash} />
                  </span>
                  {errors.password && (
                    <p className="text-red-500 text-xs absolute top-full mt-0 left-0 w-full">
                      {errors.password.message}
                    </p>
                  )}
                </div>

                <div className="p-6 mx-auto">
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      className="w-5 h-5 border-gray-300 rounded text-blue-600 focus:ring-blue-500"
                    />
                    <label htmlFor="acceptTerms" className="ml-3 text-gray-700">
                      <span className="font-semibold">Accept</span>{' '}
                      <a href="#" className="text-blue-600 hover:underline">
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a href="#" className="text-blue-600 hover:underline">
                        Privacy Policy
                      </a>.
                    </label>
                  </div>
                </div>

                <button
                  className="w-full bg-blueMain text-white py-2 px-4 rounded-md hover:bg-blue-600 text-sm font-open-sans font-bold items-center justify-center flex"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : 'Register'}
                </button>

                <div className="text-center mt-4 flex items-center justify-center">
                  <span className="border-t border-darkBlue flex-grow h-0.5"></span>
                  <span className="text-white font-bold font-open-sans w-100 h-100 px-2 py-1 rounded-full bg-darkBlue text-md">or</span>
                  <span className="border-t border-darkBlue flex-grow h-0.5"></span>
                </div>
               
                <div className="max-w-md flex items-center justify-center p-6 rounded-xl mb-5 md:mb-0 lg:mb-0 font-public-sans ">
                  <span className="text-black">
                    Already have an account? {' '}
                    <Link to="/" className="text-blue-500 hover:underline">
                      Log in
                    </Link>

                  </span>
                </div>
              </form>
            </div>

          </section>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
