"use client";
import React from 'react';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import Pagination from '@mui/material/Pagination';
import { useMyContext } from '../../../context/MyContext';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const TopupHistory = () => {
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/dashboard");
  };

  const historyData = []; // Populate with actual data

  const renderTableHeaders = () => {
    return ['Order ID', 'Amount', 'Last Balance', 'Transaction Type', 'Transaction Status', 'Generate Date'];
  };

  const renderTableRow = (row) => (
    <tr key={row.id} className="border-b dark:border-gray-600">
      <td className="p-2">{row.orderid}</td>
      <td className="p-2">{row.amount}</td>
      <td className="p-2">{row.lastBalance}</td>
      <td className="p-2">{row.transactionType}</td>
      <td className="p-2">{row.status}</td>
      <td className="p-2">{row.date}</td>
    </tr>
  );

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div className={`fixed z-20 h-full w-64 md:relative bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white mt-4 duration-300 ${window.innerWidth < 768 ? 'top-28' : ''}`}>
            <Sidebar />
          </div>
        )}
        <div className="dark:bg-[rgba(1,14,78,0.96)] w-full dark:text-white shadow-custom-light dark:shadow-none rounded-custom mt-4 p-4 md:ml-4 md:mt-4 md:mb-8 md:mr-4 custom-scrollbar overflow-y-auto">
          <button onClick={handleNavigation}
            className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 dark:bg-customPurple dark:hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full flex items-center justify-center">
            <FaCcVisa className="mr-2" size={24} />
            <FaCcMastercard className="mr-2" size={24} />
            TopUp History
          </button>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-[#0540a6dd] text-white text-[14px]">
                <tr>
                  {renderTableHeaders().map((header, index) => (
                    <th key={index} className="p-2 text-left">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {historyData.length > 0 ? historyData.map(row => renderTableRow(row)) : (
                  <tr>
                    <td colSpan="6" className="text-center p-4">No top-up history available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-4">
            <p>Showing <b>1</b> of <b>{historyData.length || 0}</b> results</p>
            <Pagination
              sx={{
                '.MuiPaginationItem-text': {
                  color: isDarkMode ? 'white' : 'defaultColor',
                },
              }}
              count={Math.ceil(historyData.length / 10)} 
              color="primary"
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopupHistory;
