import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Upload, CheckCircle, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import { Base_Url, uplodeDocument } from '../../config/config';
import axios from 'axios';
import { useAuth } from '../../routes/Auth';
import toast from 'react-hot-toast';

const UpdateKYC = () => {
  const { isDarkMode } = useMyContext();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const token = localStorage.getItem('token');
  const cusid = localStorage.getItem('cus_id');


  
  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate('/login')
    logout()
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
  }


  // Define Zod schema
  const validationSchema = z.object({
    companyName: z.string().min(1, 'Company name is required'),
    companyType: z.string().min(1, 'Company type is required'),
    gstin: z.string().min(1, 'GSTIN is required'),
    aadhaar: z.string().min(1, 'Aadhar Card is required'),
    pan: z.string().min(1, 'Pan is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const [uploads, setUploads] = useState({
    gstin: null,
    aadhaar: null,
    pan: null
  });
  const [loading, setLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState({}); 

  const companyTypes = [
    'Private Limited',
    'Public Limited',
    'Partnership',
    'Sole Proprietorship',
    'LLP',
    'Others'
  ];


  const handleSkip = () => {
    handleLogout();
  };

  
  const handleFileUpload = (field) => (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setFileErrors((prev) => ({ ...prev, [field]: 'File size should be less than 5MB' }));
        return;
      }
      setUploads((prev) => ({
        ...prev,
        [field]: file
      }));
      setFileErrors((prev) => ({ ...prev, [field]: '' }));
    }
  };

  const onSubmit = async (data) => {
    const newFileErrors = {};
    // if (!uploads.gstin) newFileErrors.gstin = 'GSTIN document is required';
    if (!uploads.aadhaar) newFileErrors.aadhaar = 'Aadhar document is required';
    if (!uploads.pan) newFileErrors.pan = 'PAN document is required';

    if (Object.keys(newFileErrors).length > 0) {
      setFileErrors(newFileErrors);
      return;
    }

    const formData = new FormData();
    formData.append('company_name', data.companyName);
    formData.append('company_type', data.companyType);
    formData.append('aadhar_number', data.aadhaar);
    formData.append('pancard_number', data.pan);
    formData.append('gst_number', data.gstin);

    // Append files if available
    if (uploads.gstin) formData.append('gst', uploads.gstin);
    if (uploads.aadhaar) formData.append('aadhar_card', uploads.aadhaar);
    if (uploads.pan) formData.append('pancard', uploads.pan);
    console.log("FomDataa",formData);
    try {
      setLoading(true);
      const response = await axios.post(`${Base_Url}${uplodeDocument}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: token,
          cus_id: cusid
        },
      });
      if (response.data.status === true) {
        toast.success(response.data.message);
        navigate('/dashboard');
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-100">
      <div className="w-full p-8 dark:bg-[rgba(1,14,78,0.96)] font-sans flex flex-col md:flex-row gap-8 h-full items-stretch">
        <div className="w-full md:w-4/12 p-6 rounded-custom dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark flex flex-col justify-between h-full">
        <button
            type="button"
            className="w-full bg-customPurple text-white p-1 rounded-custom hover:bg-purple-700 transition-all duration-300 transform hover:scale-[0.99] active:scale-95"
          >
            Update Your KYC
          </button>
          <button
            type="button"
            onClick={handleSkip}
            className="w-full bg-red-500 text-white p-1 mt-3 rounded-custom hover:bg-red-700 transition-all duration-300 transform hover:scale-[0.99] active:scale-95"
          >
            Skip
          </button>
          
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 dark:bg-[rgba(1,14,78,0.96)] overflow-y-auto custom-scrollbar h-full mt-2">
            <div>
              <label className="block text-sm font-medium dark:text-white">Company Name</label>
              <input
                type="text"
                {...register('companyName')}
                className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 focus:border-customPurple"
              />
              {errors.companyName && <p className="text-red-500 text-sm mt-1">{errors.companyName.message}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium dark:text-white">Company Type</label>
              <select
                {...register('companyType')}
                className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 focus:border-customPurple"
              > 
                <option value="">Select Company Type</option>
                {companyTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {errors.companyType && <p className="text-red-500 text-sm mt-1">{errors.companyType.message}</p>}
            </div>

            {['gstin', 'aadhaar', 'pan'].map((field) => (
              <div key={field} className="relative group">
                <label className="block text-sm font-medium dark:text-white capitalize">
                  {field === 'gstin' ? 'GSTIN' : `${field} Number`}
                </label>
                <div className="relative">
                  <input
                    type="text"
                    {...register(field)}
                    className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 pr-12 focus:border-customPurple"
                    placeholder={`Enter ${field.toUpperCase()}`}
                  />
                  <div className="absolute right-2 top-1/2 -translate-y-1/2">
                    <input
                      type="file"
                      id={`${field}-upload`}
                      className="hidden"
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={handleFileUpload(field)}
                    />
                    <label
                      htmlFor={`${field}-upload`}
                      className={`cursor-pointer p-2 rounded-full ${uploads[field] ? 'text-green-500' : 'text-customPurple'}`}
                    >
                      {uploads[field] ? (
                        <CheckCircle size={18} className="animate-scale-in" />
                      ) : (
                        <Upload size={18} />
                      )}
                    </label>
                  </div>
                </div>
                {fileErrors[field] && <p className="text-red-500 text-sm mt-1">{fileErrors[field]}</p>}
                {uploads[field] && (
                  <p className="text-xs text-green-500 mt-1 animate-fade-in">
                    {uploads[field].name}
                  </p>
                )}
              </div>
            ))}

            <button
              type="submit"
              disabled={loading}
              className={`w-full bg-customPurple text-white p-2 rounded-custom hover:bg-purple-700 transition-all duration-300 transform hover:scale-[0.99] active:scale-95 mt-6 ${loading ? 'opacity-70 cursor-not-allowed' : ''}`}
            >
              {loading ? (
                <span className="flex items-center justify-center gap-2">
                  <span className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full"></span>
                  Submitting...
                </span>
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
        <div className="w-full md:w-9/12 p-6 rounded-custom flex flex-col h-full shadow-custom-light dark:shadow-custom-dark">
       <img
           src={isDarkMode ? "/assets/images/Less_dark.png" : "/assets/images/Less.jpg"}
            alt="Image Description"
          className="rounded-custom shadow-custom-light dark:shadow-custom-dark h-full object-cover transition-transform duration-500 hover:scale-[1.02]"
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateKYC;
